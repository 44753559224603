import React, { useEffect, useState } from 'react';
import './ActividadesFueraEmpresa.scss';
import { Button, Col, DatePicker, Divider, Form, Icon, Input, Radio, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { tipoDocOptions } from '../../optList';
import moment from 'moment';

export default function ActividadesFueraEmpresa({ form, sectionName, apiForm, clientName, format, docValidator }) {

  const [hasErrors, setHasErrors] = useState(false);

  const {
    obj,
    rowData,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    handleDeleteRow,
    handleSaveOnChangeField,
    toDescriptionsPdf
  } = useFormItems(apiForm);

  const records = obj.json?.[sectionName]?.records || []
  const radioBtnValue = obj?.json?.[sectionName]?.hasOutdoorActivities

  useEffect(() => {
    if(!radioBtnValue) handleCleanRowData(sectionName, form)
  }, [radioBtnValue]);
  
  const fieldsParams = [
    {  
      type: 'input',
      jsonKeyName: "razonSocial",
      label: "Razón Social",
      id: "fueraEmpresaRazonSocial",
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocEmpresa",
      id: "fueraEmpresaTipoDoc",
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rut",
      id: "fueraEmpresaRut",
      customValidator: docValidator(form.getFieldValue('fueraEmpresaTipoDoc'), true)
    },
    {
      type: 'input',
      label: "Trabajo o actividad Laboral realizada",
      jsonKeyName: "actividad",
      id: "fueraEmpresaHireDate",
    },
  ]

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )
    return columnsArray;
  }


  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>ACTIVIDADES FUERA DE LA EMPRESA Y DEL HORARIO LABORAL</Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p>
            Declaro que participo o contribuyo en conferencias, seminarios, artículos a revistas e instituciones privadas o públicas, y/o realizo actividades docentes de cualquier tipo, fuera de mi horario laboral, en otros trabajos o actividades laborales que no entren en competencia con el giro de {clientName} o en conflicto con los intereses de ella.
          </p>
        </Col>
        <Col span={2} push={1}>
          <Form.Item>
            {form.getFieldDecorator("hasOutdoorActivities", {
              rules: [
                {required: true, message: t("messages.aml.dontForgetSelect")},
                { 
                  validator: (rule, value, callback) => {
                    if (value === true && records.length === 0) {
                      setHasErrors(true)
                      callback(true);
                    } else {
                      callback(); // Pasar la validación
                    }
                  }
                }
              ],
              initialValue: apiForm?.json?.outdoorActivities?.hasOutdoorActivities
            })(
              <Radio.Group
                onChange={({ target }) =>
                  handleSaveOnChangeField(form, target.value, sectionName, "hasOutdoorActivities", "hasOutdoorActivities")
                }
              >
                <Radio className="radio-switch" value={true}>
                  Sí
                </Radio>
                <Radio className="radio-switch" value={false}>
                  No
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {obj.json.outdoorActivities?.hasOutdoorActivities && (
        <>
          {format === 'html' &&
            <>
              <Row gutter={[20, 10]}>
                {fieldsParams.map(item => (
                  <>
                    {item.type === 'divider' &&
                      <Divider orientation={item.orientation ?? 'left'}>
                        {item?.dividerText ?? ''}
                      </Divider>
                    }
                    {(item.type === 'input' || item.type === 'select' || item.type === 'date') &&
                      <Col span={8} key={item.id}>
                        <Form.Item label={item.label}>
                          {form.getFieldDecorator(item.id, {
                            rules: [
                              { required: true, message: t("messages.aml.requiredData") },
                              { validator: item.customValidator }
                            ],
                          })(
                            <div>
                              {item.type === 'input' && (
                                <Input
                                  onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                                  value={rowData[item.jsonKeyName] ?? ""}
                                />
                              )}
                              
                              {item.type === 'select' && (
                                <Select
                                  allowClear
                                  onChange={(value) => {
                                    handleOnChangeTableElement(value, item.jsonKeyName);
                                    form.setFieldsValue({ [item.id]: value });
                                  }}
                                  style={{ width: '100%' }}
                                  value={rowData[item.jsonKeyName] ?? ""}
                                >
                                  {item.options.map(option => (
                                    <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                                  ))}
                                </Select>
                              )}
                            </div>
                          )}
                        </Form.Item>
                      </Col>
                    }
                  </>
                ))}
              </Row>
              <Row className="button-row">
                {records.length === 0 &&
                  <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
                    {t("messages.aml.registersRequired")}
                  </Col>
                }
                <Col className="addRelation" xl={3}>
                  <Button type="primary" htmlType="button" onClick={() => {
                    handleSaveOnAddRow(sectionName, "records", form, fieldsParams);
                  }} icon="plus"> Añadir </Button>
                </Col>
                <Col className="addRelation" xl={3}>
                  <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
                </Col>
              </Row>
            </>
          }
          <Row>
            {records.length > 0 && format === 'html' ?
              <Table columns={getColumns()} dataSource={records} size="middle" pagination={false}/>
              :
              toDescriptionsPdf(sectionName, fieldsParams)
            }
          </Row>
        </>
      )}
    </div>
  );
}
