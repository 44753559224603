import "./FormTrab.scss";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row, Spin, notification, Modal, message } from "antd";
import { FormLayout } from "../../../../../layouts";
import { withRouter } from "react-router-dom";
import apiConfig from '../../../../../config/api'
import { getFormPromise, getParamsPromise, sendFormPromise, signCDIFormPromise } from "../../../promises";
import { getCountriesCodePromise } from '../../../promises';
import {radioBtnIds} from './optList'
import moment from "moment";
import Logo from '../../../../../layouts/commonComponent/components/Logo/Logo'
import { validateRutHelper, validateCompanyRutHelper, } from "../../../../../helpers";
import HelloSign from 'hellosign-embedded';

import {ActividadesFueraEmpresaSection, 
        InterlockingSection, 
        IntroduccionSection, 
        MallaParentalSectionComponent, 
        OtrosConflictosSection, 
        OtrosConflictosSimpleSection, 
        ParticipacionEmpresaSectionComponent, 
        ParticipacionSociedadesSection, 
        RelacionCompetenciaSection, 
        RelacionFundacionesSection, 
        RelacionParentescoSection, 
        RelacionPepSection, 
        RelacionProveedoresSection, 
        RelLaboralAnteriorSection} from "./components";

const FormColab = ({ form, match }) => {
  const { t } = useTranslation();
  const [isValidate, setIsValidate] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [paises, setPaises] = useState(null);
  const [date, setDate] = useState(0);
  const [apiForm, _setApiForm] = useState(null);
  const [user, setUser] = useState({});
  const [colLogo, setColLogo] = useState(4);
  const [format, setFormat] = useState("html");
  const [params, setParams] = useState([]);
  const [registerData, setRegisterData] = useState({});
  const [signed, setSigned] = useState(false);
  const [openSigner, setOpenSigner] = useState(false);
  const hasSign = true;
  const [clientName, setClientName] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [sentErrors, setSentErrors] = useState(0);
  const [signData, setSignData] = useState(null);
  const apiFormRef = useRef(apiForm);
  const [isSignLoading, setIsSignLoading] = useState(false);

  const setApiForm = data => {
    apiFormRef.current = data;
    _setApiForm(data);
  };

  useEffect(() => {
    if (match.params.view === "pdf") {
      setColLogo(5);
      setFormat("pdf");
    }
    setIsloading(true);
    getCountriesCodePromise().then((response) => {
      setPaises(response)
    })
    getFormPromise(match.params.id).then((response) => {
      if (
        response.data !== null &&
        response.data !== "" &&
        response.data.status !== undefined
      ) {
        setApiForm(response.data);
        setRegisterData(response.data.recipient.record)
        setUser(response.data.recipient.request.createUser);
        setClientName(response.data.recipient.request.createUser.cliente.name);
        getParamsPromise(
          response.data.recipient.request.createUser.cliente.id
        ).then((response) => {
          setParams(response.data);
        });

        if (response.data.status === "SENT") {
          setDate(moment(response.data.receiveDate).format("DD-MM-YYYY"));
        } else {
          setDate(moment().format("DD-MM-YYYY"));
        }
      }
      setIsloading(false);
    });

    const handleMessage = (message) => {
      if(message.data && typeof message.data === 'string' && message.data.startsWith('DOCUSIGN') && apiConfig.url.startsWith(message.origin)) {
        const data = message.data.split('|')
        if(data.length === 4) {
          if(data[1] === "signing_complete") {
            const reqId = data[2] + "|" + data[3]
            sendDocument(reqId, apiFormRef.current)
          }else {
            closeHandlerSigner();
            notification["warning"]({
              message: "Firma cancelada",
            });
          }
        }
      }
    }

    window.addEventListener("message", handleMessage);
    return function () {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  
  const closeHandlerSigner = () => {
    setSignData(null)
    setOpenSigner(false)
  }

  const sendDocument = (requestId = "", formObj = apiForm) => {
    sendFormPromise(match.params.id, requestId).then((response) => {
      if (response.code === "OK") {
        let formSend = { ...formObj, status: "SENT" };
        setApiForm(formSend);
      } else {
        let errores = sentErrors+1
        setSentErrors(errores)
        notification["error"]({
          message: t("messages.aml.notifications.anErrorOcurred"),
          description: response.msg !== null ? response.msg : 'Comuníquese con soporte.aml@gesintel.cl'
        });
        setApiForm(response.form);
      }
    }).then(
      setIsSend(true)
    )
  }

  const signDocument = async () => {
    setIsSignLoading(true)
    let record = apiForm.recipient.record
    let _emails = ["no@email.com"];
    if (record.email != null) {
      _emails = record.email.split(",");
    }
    var _email = _emails[0].trim();
    let signParams = await signCDIFormPromise(apiForm.id, _email)
    setIsSignLoading(false)
    setSignData(signParams)
    if (signParams.embedUrl == "signed") {
      notification["warning"]({
        message: "Ya esta firmado"
      })
      setSigned(true)
      sendDocument(signParams.requestId)
    } else {
      if(signParams.embedUrl) {
        if(signParams.provider == "HELLOSIGN") {
          if (signParams.clientId != "") {
            const client = new HelloSign({
              clientId: signParams.clientId
            });
            let testMode = !apiConfig.url.startsWith('https://api.amlupdate')
            client.open(signParams.embedUrl, {
              testMode
            });
            client.on('sign', () => {
              setSigned(true)
              sendDocument(signParams.requestId)
            });
          } else {
            notification["error"]({
              message: "Error al iniciar servicio de Firma Electronica",
              description: signParams.message
            })
          }
        }
      } else {
        notification["error"]({
          message: "Error al iniciar servicio de Firma Electronica",
          description: signParams.message
        })
      }
    }
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fieldsToBeValidate = [
      'introduccionGerencia',
      'introduccionCargo',
      'introduccionHireDate',
      ...radioBtnIds
    ]
    
    form.validateFieldsAndScroll(fieldsToBeValidate, () => {
    }).then(() => {
      if(apiForm?.json?.family?.records?.length >= 2){
        setOpenSigner(true)
        if (hasSign) {
          signDocument()
        } else {
          sendDocument()
        }
        setOpenSigner(false)
      }else{
        notification["warning"]({
          message: "Faltan añadir registros en Malla Parental",
        });
      }
    }).catch(() => {
      notification["warning"]({
        message: "Faltan añadir datos requeridos",
      });
    })
  };

  const numTelValidator = (rule, value, callback) => {
    const regex = /^[0-9]*$/;
    if (value && value.length !== 9) {
      callback("Número debe tener 9 dígitos");
    } else if (value && !(regex.test(value))) {
      callback("Debe contenter sólo números");
    } else {
      callback();
    }
  };

  const emailValidator = (rule, value, callback) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (value && regex.test(value) === false) {
      callback("Debe ingresar un correo electrónico válido");
    } else {
      callback();
    }
  }

  const docValidator = useCallback((tipoDoc, company = false) => {
    console.log(tipoDoc)
    if (tipoDoc === "Chile-Rut") {
      return company === false ? rutValidator : rutValidatorCompany;
    } else {
      return null;
    }
  }, []); // Dependencias

  const rutValidator = (rule, value, cb) => {
    if (value && !validateRutHelper(value)) {
      cb("Documento no válido");
    }
    cb();
  };

  const rutValidatorCompany = (rule, value, cb) => {
    if (value && !validateCompanyRutHelper(value)) {
      cb("Documento empresa no válido");
    }
    cb();
  };

  return (
    <Spin spinning={isSignLoading} size='large' tip='Cargando servicio de firma'>
      <FormLayout view={match.params.view} >
        <div style={{ position: "relative" }}>
          {isLoading ? (
            <div className="form-header">
              <Row>
                <Col xs={9}></Col>
                <Col xs={6}>
                  <div className="form-wrapper" style={{ textAlign: "center" }}>
                    <Spin style={{ fontColor: "#fff" }} size={"large"} />
                  </div>
                </Col>
                <Col xs={9}></Col>
              </Row>
            </div>
          ) : (
            <>
              {apiForm !== null ? (
                <div
                  className={
                    "form-content " + format + " " + (!isValidate ? " form-validate-messages" : "")
                  }
                  >
                    <Form onSubmit={handleSubmit} className="form-form">
                      <Row
                        className="title-logo"
                        gutter={[0, 6]}
                        style={{
                          backgroundColor: "rgba(255,255,255,0.9)",
                          marginTop: "0px",
                          marginBottom: "0px",
                          textAlign: "left",
                          paddingTop: "10px",
                          paddingLeft: "10px",
                        }}
                      >
                        <Col
                          xs={24 - colLogo}
                          sm={24 - colLogo}
                          md={24 - colLogo}
                          lg={24 - colLogo}
                          xl={24 - colLogo}
                        >
                          <h3>FORMULARIO DE CONFLICTO DE INTERÉS - COLABORADORES</h3>
                        </Col>
                        <Col
                          className="logo-col"
                          xs={colLogo}
                          sm={colLogo}
                          md={colLogo}
                          lg={colLogo}
                          xl={colLogo}
                        >
                          <Logo currentUser={{ userId: user.id, subclienteId: '0' }} isForm={true} />
                        </Col>
                      </Row>
                      <Row
                        className="date"
                        gutter={[0, 6]}
                        style={{
                          backgroundColor: "rgba(255,255,255,0.9)",
                          marginTop: "0px",
                          marginBottom: "0px",
                          textAlign: "left",
                          paddingBottom: "15px",
                          paddingLeft: "10px",
                        }}
                      >
                        <Col span={2} style={{ textAlign: "left" }}>
                          Fecha:
                        </Col>
                        <Col span={4}>
                          <div
                            className="formDate"
                            style={{
                              marginLeft: "5px",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "#e8e8e8",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {date}
                          </div>
                        </Col>
                        <Col span={13} style={{ textAlign: "right" }}>
                          Folio:
                        </Col>
                        <Col span={5}>
                          <div
                            className="formDate"
                            style={{
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "#e8e8e8",
                              display: "flex",
                              justifyContent: "center",
                              marginRight: "10px",
                              marginLeft: "8px",
                            }}
                          >
                            {apiForm.folio !== null ? apiForm.folio : ""}
                          </div>
                        </Col>
                        <Col span={19} style={{ textAlign: "right" }}>
                          Proceso:
                        </Col>
                        <Col span={5}>
                          <div
                            className="formDate"
                            style={{
                              borderStyle: "solid",
                              borderWidth: "1px",
                              borderColor: "#e8e8e8",
                              display: "flex",
                              justifyContent: "center",
                              marginRight: "10px",
                              marginLeft: "8px",
                            }}
                          >
                            {apiForm.proceso === 'CREATE' ? 'Creación' : 'Actualización'}
                          </div>
                        </Col>
                      </Row>
                      {apiForm.status === "SENT" &&
                        (format === "html") ? (
                        <>
                          <br />
                          <h3 style={{ textAlign: "center" }}>
                              Estimado(a)
                              {apiForm.recipient.record.name}, le informamos que su declaración fue correctamente
                              completada, agradecemos su tiempo y disposición.
                              <br />
                            Hemos enviado una copia de la declaración realizada al
                            correo electrónico registrado:<br /><br />
                            {apiForm.recipient.record.email}
                          </h3>
                        </>
                      ) : (
                        <>
                          <IntroduccionSection
                            sectionName="basicInformation"
                            form={form}
                            apiForm={apiForm}
                            clientName={clientName}
                          />
                          <MallaParentalSectionComponent
                            docValidator={docValidator}
                            sectionName="family"
                            form={form}
                            apiForm={apiForm}
                            format={format}
                          />
                          <ParticipacionEmpresaSectionComponent
                            docValidator={docValidator}
                            sectionName="pemp"
                            form={form}
                            clientName={clientName}
                            apiForm={apiForm}
                            registerData={registerData}
                            empresasGrupo={params.empresas}
                            format={format}
                          />
                          <ParticipacionSociedadesSection
                            docValidator={docValidator}
                            sectionName="psoc"
                            paises={paises}
                            form={form} 
                            apiForm={apiForm}
                            registerData={registerData}
                            format={format}
                          />
                          <InterlockingSection
                            docValidator={docValidator}
                            sectionName="interlocking"
                            clientName={clientName}
                            form={form} 
                            apiForm={apiForm}
                            formato={format}
                          />
                          <RelacionProveedoresSection
                            docValidator={docValidator}
                            sectionName="relacionProveedor"
                            clientName={clientName}
                            form={form} 
                            apiForm={apiForm}
                            registerData={registerData}
                            empresasGrupo={params.empresas}
                            format={format}
                          />
                          <ActividadesFueraEmpresaSection
                            docValidator={docValidator}
                            sectionName="outdoorActivities"
                            clientName={clientName}
                            form={form} 
                            apiForm={apiForm}
                            format={format}
                          />
                          <RelLaboralAnteriorSection
                            docValidator={docValidator}
                            sectionName="relLabAnterior"
                            clientName={clientName}
                            form={form} 
                            apiForm={apiForm}
                            format={format}
                          />
                          <RelacionCompetenciaSection
                            docValidator={docValidator}
                            sectionName="competencia"
                            clientName={clientName}
                            form={form} 
                            apiForm={apiForm}
                            format={format}
                          />
                          <RelacionParentescoSection
                            docValidator={docValidator}
                            sectionName="relacionParentesco"
                            clientName={clientName}
                            form={form} 
                            apiForm={apiForm}
                            empresasGrupo={params.empresas}
                            format={format}
                          />
                          <RelacionPepSection
                            docValidator={docValidator}
                            sectionName="relacionPep"
                            clientName={clientName}
                            form={form} 
                            apiForm={apiForm}
                            registerData={registerData}
                            format={format}
                          />
                          <RelacionFundacionesSection
                            docValidator={docValidator}
                            sectionName="relacionFundaciones"
                            clientName={clientName}
                            form={form}
                            registerData={registerData}
                            apiForm={apiForm}
                            format={format}
                          />
                          <OtrosConflictosSection
                            docValidator={docValidator}
                            sectionName="otrosConflictos"
                            clientName={clientName}
                            form={form} 
                            apiForm={apiForm}
                            format={format}
                          />
                          <OtrosConflictosSimpleSection
                            sectionName="otrosConflictosSimple"
                            clientName={clientName}
                            form={form}
                            apiForm={apiForm}
                            format={format}
                          />

                          <Row className="subheader">
                            USO DE INFORMACIÓN CONFIDENCIAL, RESERVADA Y/O PRIVILEGIADA
                          </Row>
                          <Row className="summary">
                            <Col span={24}>
                              <p>
                                Declaro tener conocimiento de la Ley N° 18.045 en lo relativo a la prohibición de utilizar o revelar en beneficio propio o de terceros, información confidencial, reservada y/o privilegiada.
                              </p>
                              <p>
                                Asimismo, declaro tener conocimiento de que se encuentra prohibido, divulgar, revelar o consentir que otra persona acceda a un secreto comercial de la empresa y que esto constituye un delito contemplado en el artículo 284 bis del Código Penal.
                              </p>
                            </Col>
                          </Row>
                          <Row gutter={[60, 30]} className="summary">
                            <Col span={8}>
                              <p>
                                <strong>Información Confidencial:</strong> es aquella que se refiere a hechos que no presentan el carácter de esenciales y respecto de los cuales no existe obligación de darla a conocer a la Superintendencia y al público en general, o que no reviste la calidad de información privilegiada o reservada, y que sin embargo los directores, gerentes generales y gerentes de la Administradora deciden mantener internamente, bajo sigilo.
                              </p>
                            </Col>
                            <Col span={8}>
                              <p>
                                <strong>Información reservada:</strong> se refiere a ciertos hechos o antecedentes relativos a negociaciones aún pendientes que al conocerse puedan perjudicar el interés social y respecto de las cuales con la aprobación de las tres cuartas partes de los directores en ejercicio, se les dio el carácter de reservados
                              </p>
                            </Col>
                            <Col span={8}>
                              <p>
                                <strong>Información Privilegiada:</strong> es toda información que se refiera a uno o a varios emisores de valores, a sus negocios o a uno o varios valores por ellos emitidos; no divulgada al mercado; y cuyo conocimiento, por su naturaleza, sea capaz de influir en la cotización de valores emitidos
                              </p>
                            </Col>
                          </Row>
                          <Row className="subheader">
                            CONOCIMIENTO DE LA LEY N°20.393 SOBRE “RESPONSABILIDAD PENAL DE LAS PERSONAS JURÍDICAS"
                          </Row>
                          <Row className="summary">
                            <Col span={24}>
                              <p>
                                Declaro estar en conocimiento de que {clientName} ha adoptado un modelo de prevención de delitos de acuerdo a la Ley N°20.393 de “Responsabilidad Penal de las Personas Jurídicas”, y me comprometo a no cometer o participar en la comisión de ninguno de los delitos señalados en la citada Ley u otros que pudiesen incorporarse en el futuro, así como cumplir procesos y controles establecidos para dicho fin.
                              </p>
                              <p>
                                Declaro tener la obligación de comunicar al Encargado de Prevención de Delitos, todo acto o conducta que revista carácter de delito del cual tome conocimiento, mediante el Canal de Denuncias dispuesto por {clientName}.
                              </p>
                            </Col>
                          </Row>
                          <Row className="subheader">
                            <Col xl={24}>
                              DECLARACIÓN DE RESPONSABILIDAD
                            </Col>
                          </Row>
                          <Row className="summary">
                            <p>
                              Certifico que mis respuestas están completas y son correctas a mi mejor saber y entender. Además, acepto que tengo la obligación permanente de comunicar a la brevedad y por escrito a {clientName}, en el evento que se originen cambios a la presente declaración antes de la siguiente presentación, con el objeto de que se proceda a actualizar los antecedentes entregados, y si fuera el caso, también tengo la obligación de inhibirme de tomar decisiones que pudieran verse afectadas por un posible conflicto de interés declarado mientras este no se resuelva.
                            </p>
                            <p>
                              Asimismo, faculto y declaro estar en conocimiento de que {clientName}, por si o a través de terceros, podría eventualmente, y de manera aleatoria o basado en patrones de riesgo, verificar la información proporcionada.
                            </p>
                          </Row>
                        </>
                        
                      )}
                    
                    {hasSign && format === "pdf" && !signed && apiForm?.status !== 'SENT' &&
                      <Row className="button-row">
                        <Col className="submitTrabajador" offset={18} style={{fontSize:4, paddingTop:60, paddingRight: 30, color:'rgba(255,255,255,0)'}}>
                          Firma_aqui
                        </Col>
                      </Row>
                    }
                    {format === "html" && !signed && apiForm?.status !== 'SENT' &&
                      <>
                        <Row className="button-row">
                          <Col className="submitTrabajador" xl={24}>
                            <Button type="primary" onClick={handleSubmit} disabled={openSigner} icon={openSigner ? 'loading' : 'file-protect'}>
                              { hasSign ? 'Firmar' : 'Enviar' }
                            </Button>
                          </Col>
                        </Row>

                        { hasSign && signData?.embedUrl && signData?.provider === "DOCUSIGN" &&
                          <Modal visible={ true } title="Firma" width = {1200}
                            onCancel={closeHandlerSigner}
                            footer={[<Button onClick={closeHandlerSigner}>{t('messages.aml.btnClose')}</Button>]}
                          >
                            <iframe id="iframeDocument" src={signData.embedUrl} width="100%" height="480"></iframe>
                          </Modal>
                        }
                      </>
                    }
                    </Form>
                </div>
              ) : (
                <h2 style={{ textAlign: "center" }}>Formulario no encontrado</h2>
              )}
            </>
          )}
        </div>
      </FormLayout>
    </Spin>
  );
};

export default withRouter(Form.create()(FormColab));
