import getUser from './getUser'
import createUser from './createUser'
import forgotUser from './forgotUser'
import getUserById from './getUserById'
import getDomain from './getDomain'
import getEstadoTareasUser from './getEstadoTareasUser'
import getUsersRespTareas from "./getUsersRespTareas"
import changePassword from "./changePassword"
import deleteDeclarations from "./deleteDeclarations"
import getFormC57 from './getFormC57'
import getLoginPortalData from './getLoginPortalData'
import forgotPassword from './forgotPassword'
import changePasswordUserPortal from './changePasswordUserPortal'
import createNewUser from './createNewUser'
import registerUser from './registerUser'

export const getUserPromise = getUser
export const createUserPromise = createUser
export const forgotUserPromise = forgotUser
export const getUserByIdPromise = getUserById
export const getDomainPromise = getDomain
export const getEstadoTareasUserPromise = getEstadoTareasUser
export const getUsersRespTareasPromise = getUsersRespTareas
export const changePasswordPromise = changePassword
export const deleteDeclarationsPromise = deleteDeclarations
export const getFormC57Promise = getFormC57
export const getLoginPortalDataPromise = getLoginPortalData
export const forgotPasswordPromise = forgotPassword
export const changePasswordUserPortalPromise = changePasswordUserPortal
export const createNewUserPromise = createNewUser
export const registerUserPromise = registerUser
