import React, { useState, useEffect } from 'react';
import './RelacionProveedores.scss';
import { Button, Col, Divider, Form, Icon, Input, message, Radio, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { cargosOptions, relationshipOptions, tipoDocOptions, tipoEmpresaOptions } from '../../optList';

export default function RelacionProveedores({ form, sectionName, apiForm, clientName, empresasGrupo, format, docValidator, registerData }) {

  const [showTipoPropiedad, setShowTipoPropiedad] = useState(false);
  const [showEmpresaIndirectaFields, setShowEmpresaIndirectaFields] = useState(false);

  const {
    obj,
    rowData,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    toDescriptionsPdf,
    handleDeleteRow,
    handleSaveOnChangeField
  } = useFormItems(apiForm);

  const records = obj.json?.[sectionName]?.records || []
  const radioBtnValue = obj?.json?.[sectionName]?.hasRelacionProveedor

  useEffect(() => {
    if(radioBtnValue){
      setShowTipoPropiedad(rowData.cargo === "Accionista");
      setShowEmpresaIndirectaFields(rowData.cargo === "Accionista" && rowData.tipoPropiedad === "Indirecta");
    }else{
      handleCleanRowData(sectionName, form)
    }
    
  }, [rowData.cargo, rowData.tipoPropiedad, radioBtnValue]);

  useEffect(() => {
    if (rowData.parentesco === 'Mi Persona') {
    rowData.nombre = registerData.nombre
    rowData.rutRelacionado = registerData.rut
    rowData.tipoDocRelacionada = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    form.setFieldsValue({
      relacionProveedorNombre: registerData.nombre,
      relacionProveedorRutRelacionado: registerData.rut,
      relacionProveedorTipoDocRelacionada: registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    });
    }else{
      rowData.nombre = null
      rowData.rutRelacionado = null
      rowData.tipoDocRelacionada = null
      form.setFieldsValue({
        relacionProveedorNombre: null,
        relacionProveedorRutRelacionado: null,
        relacionProveedorTipoDocRelacionada: null
      });
    }
  }, [rowData.parentesco]);
  
  const fieldsParams = [
    {
      type: 'divider',
      dividerText: 'Datos de la empresa que presta servicio a '+clientName
    },
    {  
      type: 'input',
      jsonKeyName: "razonSocial",
      label: "Razón Social",
      id: "relacionProveedorRazonSocial",
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocEmpresa",
      id: "relacionProveedorTipoDoc", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rut",
      id: "relacionProveedorRut",
      customValidator: docValidator(form.getFieldValue('relacionProveedorTipoDoc'), true)
    },
    {
      type: 'select',
      label: "Tipo de Empresa",
      jsonKeyName: "tipoEmpresa",
      id: "relacionProveedorTipoEmpresa", 
      options: tipoEmpresaOptions
    },
    {
      type: 'select',
      label: "Parentesco",
      jsonKeyName: "parentesco",
      id: "relacionProveedorParentesco",
      options: relationshipOptions,
    },
    {
      type: 'select',
      label: "Empresas del Grupo",
      jsonKeyName: "grupoEmpresa",
      id: "relacionProveedorGrupo",
      options: empresasGrupo?.map(empresa => ({ val: empresa, text: empresa })) ?? [{val: "Sin Empresa", text: "Sin Empresa"}]
    },
    {
      type: 'divider',
      dividerText: `Datos de la persona relacionada a ${rowData["razonSocial"] || ''}`
    },
    {
      type: 'input',
      label: "Nombre",
      jsonKeyName: "nombre",
      id: "relacionProveedorNombre", 
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocRelacionada",
      id: "relacionProveedorTipoDocRelacionada", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rutRelacionado",
      id: "relacionProveedorRutRelacionado",
      customValidator: docValidator(form.getFieldValue('relacionProveedorTipoDocRelacionada'))
    },
    {
      type: 'select',
      label: "Cargo",
      jsonKeyName: "cargo",
      id: "relacionProveedorCargo",
      options: cargosOptions,
    },
    showTipoPropiedad && {
      type: 'select',
      label: "Tipo de Propiedad",
      jsonKeyName: "tipoPropiedad",
      id: "relacionProveedorTipoPropiedad",
      options: [{val: "Directa", text: "Directa"}, {val: "Indirecta", text: "Indirecta"}],
    },
    showTipoPropiedad && {
      type: 'input',
      label: "Porcentaje de Participación",
      jsonKeyName: "porcentajeParticipacion",
      id: "relacionProveedorporcentajeParticipacion",
    },
    showEmpresaIndirectaFields && {
      type: 'input',
      label: "Nombre de Empresa Propiedad Indirecta",
      jsonKeyName: "nombreEmpresaIndirecta",
      id: "relacionProveedornombreEmpresaIndirecta",
    },
    showEmpresaIndirectaFields && {
      type: 'select',
      label: "Tipo de documento Empresa Propiedad Indirecta",
      jsonKeyName: "tipoDocEmpresaIndirecta",
      id: "relacionProveedortipoDocEmpresaIndirecta", 
      options: tipoDocOptions
    },
    showEmpresaIndirectaFields && {
      type: 'input',
      label: "Documento Empresa Propiedad Indirecta",
      jsonKeyName: "rutEmpresaIndirecta",
      id: "relacionProveedorrutEmpresaIndirecta", 
      options: tipoDocOptions,
      customValidator: docValidator(form.getFieldValue('relacionProveedortipoDocEmpresaIndirecta'), true)
    }
  ]

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )

    return columnsArray;
  }


  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>RELACIÓN CON PROVEEDORES, CONTRATISTAS, Y/O CLIENTES</Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p>
            Declaro que tengo participación, directa o indirectamente, o lo hace mi cónyuge, conviviente civil y/o parientes hasta el segundo grado de consanguinidad o afinidad inclusive, hasta donde tengo conocimiento, en sociedades que sean proveedoras, contratistas o clientes de {clientName}, ya sea como propietario, socio, accionista principal, director, o como ejecutivo principal, gerente, administrador, o con cualquier cargo que suponga capacidad para tomar decisiones y obligar al proveedor, contratista y/o cliente.
          </p>
        </Col>
        <Col span={2} push={1}>
          <Form.Item>
          {
            form.getFieldDecorator("hasRelacionProveedor", {
              rules: [
                {required: true, message: t("messages.aml.dontForgetSelect")},
                { 
                  validator: (rule, value, callback) => {
                    if (value === true && records.length === 0) {
                      callback(true);
                    } else {
                      callback(); // Pasar la validación
                    }
                  }
                }
              ],
              initialValue: apiForm?.json?.relacionProveedor?.hasRelacionProveedor // Default to false if not available
            })(
              <Radio.Group
                onChange={({ target }) =>
                  handleSaveOnChangeField(form, target.value, sectionName, "hasRelacionProveedor", "hasRelacionProveedor")
                }
              >
                <Radio className="radio-switch" value={true}>
                  Sí
                </Radio>
                <Radio className="radio-switch" value={false}>
                  No
                </Radio>
              </Radio.Group>
            )
          }
          </Form.Item>
        </Col>
      </Row>
      {obj.json.relacionProveedor?.hasRelacionProveedor && (
        <>
          { format === 'html' &&
          <>
            <Row gutter={[20, 10]}>
              {fieldsParams.map(item => (
                <>
                  {item.type === 'divider' &&
                    <Divider orientation={item.orientation ?? 'left'}>
                      {item?.dividerText ?? ''}
                    </Divider>
                  }
                  {(item.type === 'input' || item.type === 'select' || item.type === 'radio') &&
                    <Col span={8} key={item.id}>
                      <Form.Item label={item.label}>
                        {form.getFieldDecorator(item.id, {
                          rules: [
                            { validator: item.customValidator },
                            { required: true, message: t("messages.aml.requiredData") }
                          ],
                        })(
                          <div>
                            {item.type === 'input' && (
                              <Input
                                onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                                value={rowData[item.jsonKeyName] ?? ""}
                              />
                            )}
                            
                            {item.type === 'select' && (
                              <Select
                                allowClear
                                onChange={(value) => {
                                  handleOnChangeTableElement(value, item.jsonKeyName);
                                  form.setFieldsValue({ [item.id]: value });
                                }}
                                style={{ width: '100%' }}
                                value={rowData[item.jsonKeyName] ?? ""}
                              >
                                {item.options.map(option => (
                                  <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                                ))}
                              </Select>
                            )}
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                  }
                </>
              ))}
            </Row>
            <Row className="button-row">
              {records.length === 0 &&
                <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
                  {t("messages.aml.registersRequired")}
                </Col>
              }
              <Col className="addRelation" xl={3}>
                <Button type="primary" htmlType="button" onClick={() => {
                  handleSaveOnAddRow(sectionName, "records", form, fieldsParams);
                }} icon="plus"> Añadir </Button>
              </Col>
              <Col className="addRelation" xl={3}>
                <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
              </Col>
            </Row>
          </>
          }
          <Row>
            {records.length > 0 && format === 'html' ?
              <Table columns={getColumns()} dataSource={records} size="middle" pagination={false}/>
              :
              toDescriptionsPdf(sectionName, fieldsParams)
            }
          </Row>
        </>
      )}
    </div>
  );
}
