import React, { useEffect, useState } from 'react';
import './RelacionCompetencia.scss';
import { Button, Col, Divider, Form, Icon, Input, Radio, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { cargosOptions, relationshipOptions, tipoDocOptions } from '../../optList';

export default function RelacionCompetencia({ form, sectionName, apiForm, clientName, format, docValidator }) {

  const {
    obj,
    rowData,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    toDescriptionsPdf,
    handleDeleteRow,
    handleSaveOnChangeField
  } = useFormItems(apiForm);

  const records = obj.json?.[sectionName]?.records || []
  const radioBtnValue = obj?.json?.[sectionName]?.hasCompetencia

  useEffect(() => {
    if(!radioBtnValue) handleCleanRowData(sectionName, form)
  }, [radioBtnValue]);

  const fieldsParams = [
    {
      type: 'divider',
      dividerText: 'Datos de la empresa competidora de ' +clientName
    },
    {  
      type: 'input',
      jsonKeyName: "razonSocial",
      label: "Razón Social",
      id: "competenciaRazonSocial",
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocEmpresa",
      id: "competenciaTipoDoc", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rut",
      id: "competenciaRut", 
      customValidator: docValidator(form.getFieldValue('competenciaTipoDoc'), true)
    },
    {
      type: 'divider',
      dividerText: `Datos de la persona relacionada a ${rowData["razonSocial"] || ''}`
    },
    {
      type: 'select',
      label: "Parentesco",
      jsonKeyName: "parentesco",
      id: "psocParentesco",
      options: relationshipOptions.slice(1),
    },
    {
      type: 'input',
      label: "Nombre",
      jsonKeyName: "nombre",
      id: "competenciaNombre", 
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocRelacionada",
      id: "competenciaTipoDocRelacionado", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rutRelacionado",
      id: "competenciaRutRelacionado",
      customValidator: docValidator(form.getFieldValue('competenciaTipoDocRelacionado'))
    },
    {
      type: 'select',
      label: "Cargo",
      jsonKeyName: "cargo",
      id: "competenciaCargo",
      options: cargosOptions,
    },
  ]

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )

    return columnsArray;
  }


  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>RELACIÓN CON LA COMPETENCIA</Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p>
            Declaro que mi cónyuge, conviviente civil o pareja es persona trabajadora de la competencia o que tengo relaciones de parentesco hasta el segundo grado de consanguinidad o afinidad inclusive con personas trabajadoras de la competencia.
          </p>
        </Col>
        <Col span={2} push={1}>
          <Form.Item>
            {form.getFieldDecorator("hasCompetencia", {
              rules: [
                {required: true, message: t("messages.aml.dontForgetSelect")},
                { 
                  validator: (rule, value, callback) => {
                    if (value === true && records.length === 0) {
                      callback(true);
                    } else {
                      callback(); // Pasar la validación
                    }
                  }
                }
              ],
              initialValue: apiForm?.json?.competencia?.hasCompetencia
            })(
              <Radio.Group
                onChange={({ target }) =>
                  handleSaveOnChangeField(form, target.value, sectionName, "hasCompetencia", "hasCompetencia")
                }
              >
                <Radio className="radio-switch" value={true}>
                  Sí
                </Radio>
                <Radio className="radio-switch" value={false}>
                  No
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {obj.json.competencia?.hasCompetencia && (
        <>
          <Row gutter={[20, 10]}>
            {fieldsParams.map(item => (
              <>
                {item.type === 'divider' &&
                  <Divider orientation={item.orientation ?? 'left'}>
                    {item?.dividerText ?? ''}
                  </Divider>
                }
                {(item.type === 'input' || item.type === 'select' || item.type === 'radio') &&
                  <Col span={8} key={item.id}>
                    <Form.Item label={item.label}>
                      {form.getFieldDecorator(item.id, {
                        rules: [
                          { validator: item.customValidator },
                          { required: true, message: t("messages.aml.requiredData") }
                        ],
                      })(
                        <div>
                          {item.type === 'input' && (
                            <Input
                              onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                              value={rowData[item.jsonKeyName] ?? ""}
                            />
                          )}
                          
                          {item.type === 'select' && (
                            <Select
                              allowClear
                              onChange={(value) => {
                                handleOnChangeTableElement(value, item.jsonKeyName);
                                form.setFieldsValue({ [item.id]: value });
                              }}
                              style={{ width: '100%' }}
                              value={rowData[item.jsonKeyName] ?? ""}
                            >
                              {item.options.map(option => (
                                <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                              ))}
                            </Select>
                          )}
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                }
              </>
            ))}
          </Row>
          <Row className="button-row">
            {records.length === 0 &&
              <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
                {t("messages.aml.registersRequired")}
              </Col>
            }
            <Col className="addRelation" xl={3}>
              <Button type="primary" htmlType="button" onClick={() => {
                handleSaveOnAddRow(sectionName, "records", form, fieldsParams);
              }} icon="plus"> Añadir </Button>
            </Col>
            <Col className="addRelation" xl={3}>
              <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
            </Col>
          </Row>
          <Row>
            {records.length > 0 && format === 'html' ?
              <Table columns={getColumns()} dataSource={records} size="middle" pagination={false}/>
              :
              toDescriptionsPdf(sectionName, fieldsParams)
            }
          </Row>
        </>
      )}
    </div>
  );
}
