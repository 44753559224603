import React, { useState, useEffect } from 'react';
import './ParticipacionSociedades.scss';
import { Button, Col, Divider, Form, Icon, Input, Radio, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { cargosOptions, relationshipOptions, tipoDocOptions } from '../../optList';

export default function ParticipacionSociedades({ form, sectionName, apiForm, paises, format, docValidator, registerData }) {

  const [showTipoPropiedad, setShowTipoPropiedad] = useState(false);
  const [showEmpresaIndirectaFields, setShowEmpresaIndirectaFields] = useState(false);

  const {
    obj,
    rowData,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    toDescriptionsPdf,
    handleDeleteRow,
    handleSaveOnChangeField
  } = useFormItems(apiForm);

  const records = obj?.json?.[sectionName]?.records ?? []
  const radioBtnValue = obj?.json?.[sectionName]?.hasPsoc

  useEffect(() => {
    if(radioBtnValue){
      setShowTipoPropiedad(rowData.cargoRelacionado === "Accionista");
      setShowEmpresaIndirectaFields(rowData.cargoRelacionado === "Accionista" && rowData.tipoPropiedad === "Indirecta");
    }else{
      handleCleanRowData(sectionName, form)
    }
  }, [rowData.cargoRelacionado, rowData.tipoPropiedad, radioBtnValue]);

  useEffect(() => {
    if (rowData.parentesco === 'Mi Persona') {
      setWithRegisterData()
    }else{
      setRegisterWithNull()
    }
  }, [rowData.parentesco]);
  
  const fieldsParams = [
    {
      id: 'divider',
      type: 'divider',
      dividerText: 'Datos de la empresa en la que posee participación'
    },
    {  
      type: 'input',
      jsonKeyName: "razonSocial",
      label: "Razón Social",
      id: "psocRazonSocial",
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocEmpresa",
      id: "psocEmpresaTipoDoc", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rutEmpresa",
      id: "psocEmpresaRut",
      customValidator: docValidator(form.getFieldValue('psocEmpresaTipoDoc'), true),
    },
    {
      type: 'select',
      label: "País de Constitución",
      jsonKeyName: "paisConstitucion",
      id: "psocCountry",
      options: paises.map(item => {
        return {val: item.country, text: item.country}
      }),
    },
    {
      id: 'divider',
      type: 'divider',
      dividerText: `Datos de la persona relacionada a ${rowData["razonSocial"] || ''}`
    },
    {
      type: 'select',
      label: "Parentesco",
      jsonKeyName: "parentesco",
      id: "psocParentesco",
      options: relationshipOptions,
    },
    {
      type: 'input',
      label: "Nombre",
      jsonKeyName: "nombreRelacionado",
      id: "psocNombre",
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocRelacionado",
      id: "psocRelTipoDoc", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rutRelacionado",
      id: "psocRelacionadoRut",
      customValidator: docValidator(form.getFieldValue('psocRelTipoDoc')),
    },
    {
      type: 'select',
      label: "Cargo",
      jsonKeyName: "cargoRelacionado",
      id: "psocCargo",
      options: cargosOptions,
    },
    showTipoPropiedad && {
      type: 'select',
      label: "Tipo de Propiedad",
      jsonKeyName: "tipoPropiedad",
      id: "psocTipoPropiedad",
      options: [{val: "Directa", text: "Directa"}, {val: "Indirecta", text: "Indirecta"}],
    },
    showTipoPropiedad && {
      type: 'input',
      label: "Porcentaje de Participación",
      jsonKeyName: "porcentajeParticipacion",
      id: "psocporcentajeParticipacion",
    },
    showEmpresaIndirectaFields && {
      type: 'input',
      label: "Nombre de Empresa Propiedad Indirecta",
      jsonKeyName: "nombreEmpresaIndirecta",
      id: "psocnombreEmpresaIndirecta",
    },
    showEmpresaIndirectaFields && {
      type: 'select',
      label: "Tipo de documento Empresa Propiedad Indirecta",
      jsonKeyName: "tipoDocEmpresaIndirecta",
      id: "psoctipoDocEmpresaIndirecta", 
      options: tipoDocOptions
    },
    showEmpresaIndirectaFields && {
      type: 'input',
      label: "Documento Empresa Propiedad Indirecta",
      jsonKeyName: "rutEmpresaIndirecta",
      id: "psocrutEmpresaIndirecta",
      customValidator: docValidator(form.getFieldValue('psoctipoDocEmpresaIndirecta'), true),
      options: tipoDocOptions
    }
  ].filter(Boolean)

  const setWithRegisterData = () => {
    rowData.nombreRelacionado = registerData.nombre
    rowData.rutRelacionado = registerData.rut
    rowData.tipoDocRelacionado = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    form.setFieldsValue({
      psocNombre: registerData.nombre,
      psocRelacionadoRut: registerData.rut,
      psocRelTipoDoc: registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
    });
  }

  const setRegisterWithNull = () => {
    form.setFieldsValue({
      psocNombre: null,
      psocRelacionadoRut: null,
      psocRelTipoDoc: null
    });
    rowData.nombreRelacionado = null
    rowData.rutRelacionado = null
    rowData.tipoDocRelacionado = null
  }

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )

    return columnsArray;
  }


  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>PARTICIPACIÓN EN OTRAS SOCIEDADES </Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p>
            Declaro que, hasta donde tengo conocimiento, participo, o lo hace mi cónyuge, y/o parientes hasta el tercer grado de consanguinidad o afinidad inclusive, en sociedades, en forma directa o a través de otras personas naturales o jurídicas, con un 10% o más de su capital, o bien mi cónyuge y/o parientes hasta el tercer grado de consanguinidad o afinidad inclusive ocupa el cargo de director, gerente, administrador, ejecutivo principal u otro equivalente en otras Sociedades, tanto en Chile como en el extranjero.
          </p>
        </Col>
        <Col span={2} push={1}>
          <Form.Item>
            {form.getFieldDecorator("hasPsoc", {
              rules: [
                {required: true, message: t("messages.aml.dontForgetSelect")},
                { 
                  validator: (rule, value, callback) => {
                    if (value === true && records.length === 0) {
                      callback(false);
                    } else {
                      callback(); // Pasar la validación
                    }
                  }
                }
              ],
              initialValue: apiForm?.json?.psoc?.hasPsoc
            })(
              <Radio.Group
                onChange={({ target }) =>
                  handleSaveOnChangeField(form, target.value, sectionName, "hasPsoc", "hasPsoc")
                }
              >
                <Radio className="radio-switch" value={true}>
                  Sí
                </Radio>
                <Radio className="radio-switch" value={false}>
                  No
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {obj.json.psoc?.hasPsoc && (
        <>
          {format === 'html' &&
          <>
            <Row gutter={[20, 10]}>
              {fieldsParams.map(item => (
                <>
                  {item.type === 'divider' &&
                    <Divider orientation={item.orientation ?? 'left'}>
                      {item?.dividerText ?? ''}
                    </Divider>
                  }
                  {(item.type === 'input' || item.type === 'select' || item.type === 'radio') &&
                    <Col span={8} key={item.id}>
                      <Form.Item label={item.label}>
                        {form.getFieldDecorator(item.id, {
                          rules: [
                            { required: true, message: t("messages.aml.requiredData") },
                            { validator: item.customValidator }
                          ],
                        })(
                          <div>
                            {item.type === 'input' && (
                              <Input
                                onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                                value={rowData[item.jsonKeyName] ?? ""}
                              />
                            )}
                            
                            {item.type === 'select' && (
                              <Select
                                allowClear
                                onChange={(value) => {
                                  handleOnChangeTableElement(value, item.jsonKeyName);
                                  form.setFieldsValue({ [item.id]: value });
                                }}
                                style={{ width: '100%' }}
                                value={rowData[item.jsonKeyName] ?? ""}
                              >
                                {item.options.map(option => (
                                  <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                                ))}
                              </Select>
                            )}
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                  }
                </>
              ))}
            </Row>
            <Row className="button-row">
              {records.length === 0 &&
                <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
                  {t("messages.aml.registersRequired")}
                </Col>
              }
              <Col className="addRelation" xl={3}>
                <Button type="primary" htmlType="button" onClick={() => {
                  handleSaveOnAddRow(sectionName, "records", form, fieldsParams);
                }} icon="plus"> Añadir </Button>
              </Col>
              <Col className="addRelation" xl={3}>
                <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
              </Col>
            </Row>
          </>
          }
          <Row>
          {records.length > 0 && format === 'html' ?
            <Table columns={getColumns()} dataSource={records} size="middle" pagination={false}/>
            :
            toDescriptionsPdf(sectionName, fieldsParams)
          }
          </Row>
        </>
      )}
    </div>
  );
}
