import './RelacionFundaciones.scss';
import { Button, Col, Divider, Form, Icon, Input, Radio, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { cargosOptions, relationshipOptions, tipoDocOptions, tipoFundacionOptions } from '../../optList';
import { useEffect, useState } from 'react';

export default function RelacionFundaciones({ form, sectionName, apiForm, format, docValidator, registerData }) {

  const {
    obj,
    rowData,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    toDescriptionsPdf,
    handleDeleteRow,
    handleSaveOnChangeField
  } = useFormItems(apiForm);

  useEffect(() => {
    if (rowData.parentescoRelacionado === 'Mi Persona') {
      rowData.nombreRelacionado = registerData.nombre
      rowData.rutRelacionado = registerData.rut
      rowData.tipoDocRelacionado = registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
      form.setFieldsValue({
        relFundacionesNombre: registerData.nombre,
        relFundacionesRutRelacionado: registerData.rut,
        relFundacionesTipoDoc: registerData.tipoDoc === 'Rut' ? 'Chile-Rut' : 'Otros'
      });
    }else{
      rowData.nombreRelacionado = null
      rowData.rutRelacionado = null
      rowData.tipoDocRelacionado = null
      form.setFieldsValue({
        relFundacionesNombre: null,
        relFundacionesRutRelacionado: null,
        relFundacionesTipoDoc: null
      });
    }
  }, [rowData.parentescoRelacionado]);

  const records = obj.json?.[sectionName]?.records || []
  const radioBtnValue = obj?.json?.[sectionName]?.hasFundaciones

  useEffect(() => {
    if(!radioBtnValue) handleCleanRowData(sectionName, form)
  }, [radioBtnValue]);

  const fieldsParams = [
    {
      type: 'divider',
      dividerText: 'Datos de la Institución'
    },
    {  
      type: 'select',
      jsonKeyName: "tipoInstitucion",
      label: "Tipo de Institución",
      id: "relFundacionesTipoInsitucion",
      options: tipoFundacionOptions
    },
    {
      type: 'input',
      label: "Nombre de la Institución",
      jsonKeyName: "nombreInstitucion",
      id: "relFundacionesNombreInstitucion", 
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocInstitucion",
      id: "relFundacionesTipoDocInstitucion", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rutInstitucion",
      id: "relFundacionesDocInstituciones",
      customValidator: docValidator(form.getFieldValue('relFundacionesTipoDocInstitucion'), true)
    },
    {
      type: 'divider',
      dividerText: `Datos de la persona relacionada a ${rowData["nombreInstitucion"] || ''}`
    },
    {
      type: 'select',
      label: "Parentesco",
      jsonKeyName: "parentescoRelacionado",
      id: "relFundacionesParentesco",
      options: relationshipOptions,
    },
    {
      type: 'input',
      label: "Nombre",
      jsonKeyName: "nombreRelacionado",
      id: "relFundacionesNombre", 
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocRelacionado",
      id: "relFundacionesTipoDoc", 
      options: tipoDocOptions
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rutRelacionado",
      id: "relFundacionesRutRelacionado", 
      customValidator: docValidator(form.getFieldValue('relFundacionesTipoDoc'))
    },
    {
      type: 'select',
      label: "Cargo",
      jsonKeyName: "cargoRelacionado",
      id: "relFundacionesCargo",
      options: cargosOptions,
    },
    {
      type: 'input',
      label: "Área en la que se desarrolla",
      jsonKeyName: "area",
      id: "relFundacionesArea",
    }
  ]

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )

    return columnsArray;
  }


  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>RELACIÓN CON FUNDACIONES, CORPORACIONES, INSTITUCIONES DE BENEFICIENCIA Y/O SIN FINES DE LUCRO</Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p>
            Declaro que tengo participación, directa o indirectamente, o lo hace mi cónyuge, conviviente civil y/o parientes hasta el segundo grado de consanguinidad o afinidad, inclusive, como fundador, director, consejero, gerente, administrador, u otro similar, hasta donde tengo conocimiento, en fundaciones, corporaciones, instituciones de beneficencia y/o sin fines de lucro.
          </p>
        </Col>
        <Col span={2} push={1}>
        <Form.Item>
          {form.getFieldDecorator("hasFundaciones", {
            rules: [
              {required: true, message: t("messages.aml.dontForgetSelect")},
              { 
                validator: (rule, value, callback) => {
                  if (value === true && records.length === 0) {
                    callback(true);
                  } else {
                    callback(); // Pasar la validación
                  }
                }
              }
            ],
            initialValue: apiForm?.json?.relacionFundaciones?.hasFundaciones
          })(
            <Radio.Group
              onChange={({ target }) =>
                handleSaveOnChangeField(form, target.value, sectionName, "hasFundaciones", "hasFundaciones")
              }
            >
              <Radio className="radio-switch" value={true}>
                Sí
              </Radio>
              <Radio className="radio-switch" value={false}>
                No
              </Radio>
            </Radio.Group>
          )}
        </Form.Item>
        </Col>
      </Row>
      {obj.json.relacionFundaciones?.hasFundaciones && (
        <>
          { format === 'html' &&
          <>
            <Row gutter={[20, 10]}>
              {fieldsParams.map(item => (
                <>
                  {item.type === 'divider' &&
                    <Divider orientation={item.orientation ?? 'left'}>
                      {item?.dividerText ?? ''}
                    </Divider>
                  }
                  {(item.type === 'input' || item.type === 'select' || item.type === 'radio') &&
                    <Col span={8} key={item.id}>
                      <Form.Item label={item.label}>
                        {form.getFieldDecorator(item.id, {
                          rules: [
                            { validator: item.customValidator },
                            { required: true, message: t("messages.aml.requiredData") }
                          ],
                        })(
                          <div>
                            {item.type === 'input' && (
                              <Input
                                onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                                value={rowData[item.jsonKeyName] ?? ""}
                              />
                            )}
                            
                            {item.type === 'select' && (
                              <Select
                                allowClear
                                onChange={(value) => {
                                  handleOnChangeTableElement(value, item.jsonKeyName);
                                  form.setFieldsValue({ [item.id]: value });
                                }}
                                style={{ width: '100%' }}
                                value={rowData[item.jsonKeyName] ?? ""}
                              >
                                {item.options.map(option => (
                                  <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                                ))}
                              </Select>
                            )}
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                  }
                </>
              ))}
            </Row>
            <Row className="button-row">
              {records.length === 0 &&
                <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
                  {t("messages.aml.registersRequired")}
                </Col>
              }
              <Col className="addRelation" xl={3}>
                <Button type="primary" htmlType="button" onClick={() => {
                  handleSaveOnAddRow(sectionName, "records", form, fieldsParams);
                }} icon="plus"> Añadir </Button>
              </Col>
              <Col className="addRelation" xl={3}>
                <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
              </Col>
            </Row>
          </>
          }
          <Row>
            {records.length > 0 && format === 'html' ?
              <Table columns={getColumns()} dataSource={records} size="middle" pagination={false}/>
              :
              toDescriptionsPdf(sectionName, fieldsParams)
            }
          </Row>
        </>
      )}
    </div>
  );
}
