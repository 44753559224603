import './OtrosConflictos.scss';
import { Button, Col, DatePicker, Divider, Form, Icon, Input, Radio, Row, Select, Table } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { tipoDocOptions } from '../../optList';
import moment from 'moment';
import { useEffect, useState } from 'react';

export default function OtrosConflictos({ form, sectionName, apiForm, clientName, format, docValidator }) {

  const {
    obj,
    rowData,
    handleSaveOnAddRow,
    handleOnChangeTableElement,
    handleCleanRowData,
    handleDeleteRow,
    handleSaveOnChangeField,
    toDescriptionsPdf
  } = useFormItems(apiForm);

  const records = obj.json?.[sectionName]?.records || []
  const radioBtnValue = obj?.json?.[sectionName]?.hasOtherConflicts

  useEffect(() => {
    if(!radioBtnValue) handleCleanRowData(sectionName, form)
  }, [radioBtnValue]);

  const fieldsParams = [
    {
      type: 'divider',
      text: 'Datos de la empresa que origina el posible Conflicto de Interés'
    },
    {  
      type: 'input',
      jsonKeyName: "razonSocialEmpresa",
      label: "Razón Social",
      id: "otrosConflictosRazonSocial",
      isRequired: false
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocEmpresa",
      id: "otrosConflictosTipoDocEmpresa", 
      options: tipoDocOptions,
      isRequired: false
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rutEmpresa",
      id: "otrosConflictosRutEmpresa",
      customValidator: docValidator(form.getFieldValue('otrosConflictosTipoDocEmpresa'), true),
      isRequired: false
    },
    {
      type: 'divider',
      text: 'Datos de la persona que origina el posible Conflicto de Interés'
    },
    {  
      type: 'input',
      jsonKeyName: "nombreRelacionado",
      label: "Nombre",
      id: "otrosConflictosNombreRelacionado",
      isRequired: false
    },
    {
      type: 'select',
      label: "Tipo de documento de identidad",
      jsonKeyName: "tipoDocRelacionado",
      id: "otrosConflictosTipoDocRelacionado", 
      options: tipoDocOptions,
      isRequired: false
    },
    {
      type: 'input',
      label: "Documento de identidad",
      jsonKeyName: "rutRelacionado",
      id: "otrosConflictosRutRelacionado", 
      isRequired: false,
      customValidator: docValidator(form.getFieldValue('otrosConflictosTipoDocRelacionado'))
    },
    {
      type: 'divider',
      text: 'Comentarios'
    },
    {
      type: 'text',
      label: 'Ingrese una breve descripción de la situación',
      jsonKeyName: 'descripcionSituacion',
      id: 'otrosConflictosDescripcion'
    }
 
  ]

  const getColumns = () => {
    const columnsArray = fieldsParams.map(field=> {
      return {title: field.label, dataIndex: field.jsonKeyName}
    })
    columnsArray.push(
      {
        title: "Acción",
        dataIndex: "",
        key: "x",
        render: (text, record, index) => (
          <a onClick={() => handleDeleteRow(sectionName, "records", index)}>
            <Icon type="delete" />
          </a>
        ),
      }
    )

    return columnsArray;
  }


  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>OTROS CONFLICTOS DE INTERÉS </Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p style={{marginBottom: 0}}>
            Declaro que tengo conocimiento de algún otro conflicto de interés no abordado en las preguntas anteriores y que pudiera afectar o influir de cualquier forma en los intereses de {clientName}, tales como la existencia de relación laboral, comercial, o de negocios con amigos íntimos, parientes por consanguinidad o afinidad hasta tercer y cuarto grado.
          </p>
        </Col>
        <Col span={2} push={1}>
          <Form.Item>
            {form.getFieldDecorator("hasOtherConflicts", {
              rules: [
                {required: true, message: t("messages.aml.dontForgetSelect")},
                { 
                  validator: (rule, value, callback) => {
                    if (value === true && records.length === 0) {
                      callback(true);
                    } else {
                      callback(); // Pasar la validación
                    }
                  }
                }
              ],
              initialValue: apiForm?.json?.otrosConflictos?.hasOtherConflicts
            })(
              <Radio.Group
                onChange={({ target }) =>
                  handleSaveOnChangeField(form, target.value, sectionName, "hasOtherConflicts", "hasOtherConflicts")
                }
              >
                <Radio className="radio-switch" value={true}>
                  Sí
                </Radio>
                <Radio className="radio-switch" value={false}>
                  No
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {obj.json.otrosConflictos?.hasOtherConflicts && (
        <>
        {format === 'html' &&
        <>
          <Row gutter={[20, 0]}>
            {fieldsParams.map(item => (
              <>
                {item.type === 'divider' &&
                  <Divider orientation={item.orientation ?? 'left'}  orientationMargin='right'>
                    {item?.text ?? ''}
                  </Divider>
                }
                {(item.type === 'input' || item.type === 'select' || item.type === 'date' || item.type === 'text') &&
                  <Col span={8} key={item.id}>
                    <Form.Item label={item.label}>
                      {form.getFieldDecorator(item.id, {
                        rules: [
                          { required: item.isRequired ?? true, message: t("messages.aml.requiredData") },
                          { validator: item.customValidator }
                        ],
                      })(
                        <div>
                          {item.type === 'input' && (
                            <Input
                              onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                              value={rowData[item.jsonKeyName] ?? ""}
                            />
                          )}
                          
                          {item.type === 'select' && (
                            <Select
                              allowClear
                              onChange={(value) => {
                                handleOnChangeTableElement(value, item.jsonKeyName);
                                form.setFieldsValue({ [item.id]: value });
                              }}
                              style={{ width: '100%' }}
                              value={rowData[item.jsonKeyName] ?? ""}
                            >
                              {item.options.map(option => (
                                <Select.Option value={option.val} key={option.key}>{option.text}</Select.Option>
                              ))}
                            </Select>
                          )}

                          {item.type === 'text' && (
                            <Input.TextArea
                              onChange={(e) => handleOnChangeTableElement(e.target.value, item.jsonKeyName)}
                              value={rowData[item.jsonKeyName] ?? ""}
                            />
                          )}

                          {item.type === 'date' && (
                            <DatePicker
                              format="DD/MM/YYYY"
                              placeholder=''
                              style={{width: '100%'}}
                              onChange={(momentObj) => {
                                const dateToString = momentObj ? moment(momentObj).format("YYYY/MM/DD") : null
                                handleOnChangeTableElement(dateToString, item.jsonKeyName);
                                form.setFieldsValue({ [item.id]: dateToString });
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                }
              </>
            ))}
          </Row>
          <Row className="button-row">
            {records.length === 0 &&
              <Col span={24} style={{ color: 'red' }} className="missing-registers ant-form-explain">
                {t("messages.aml.registersRequired")}
              </Col>
            }
            <Col className="addRelation" xl={3}>
              <Button type="primary" htmlType="button" onClick={() => {
                handleSaveOnAddRow(sectionName, "records", form, fieldsParams);
              }} icon="plus"> Añadir </Button>
            </Col>
            <Col className="addRelation" xl={3}>
              <Button type="primary" htmlType="button" icon="delete" onClick={() => handleCleanRowData(sectionName, form)}> Limpiar </Button>
            </Col>
          </Row>
        </>
        }
          <Row>
          {records.length > 0 && format === 'html' ?
            <Table columns={getColumns()} dataSource={records} size="middle" pagination={false}/>
            :
            toDescriptionsPdf(sectionName, fieldsParams)
          }
          </Row>
        </>
      )}
    </div>
  );
}
