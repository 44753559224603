import './OtrosConflictosSimple.scss';
import { Col, Form, Icon, Input, Radio, Row } from 'antd';
import useFormItems from '../../../../customHooks/useFormItems';
import { t } from 'i18next';
import { tipoDocOptions } from '../../optList';
import { useEffect, useState } from 'react';

export default function OtrosConflictosSimple({ form, sectionName, apiForm, clientName, format }) {

  const [hasErrors, setHasErrors] = useState(false);

  const {
    obj,
    handleCleanRowData,
    handleDeleteRow,
    handleSaveOnChangeField,
    toDescriptionsPdf
  } = useFormItems(apiForm);

  const records = obj.json?.[sectionName]?.records || []
  const radioBtnValue = obj?.json?.[sectionName]?.hasOtherConflicts

  useEffect(() => {
    if(!radioBtnValue) handleCleanRowData(sectionName, form)
  }, [radioBtnValue]);

  return (
    <div className='generic-form-malla-section'>
      <Row className="subheader" style={{ marginTop: "0px" }}>
        <Col xl={24}>OTROS CONFLICTOS DE INTERÉS </Col>
      </Row>
      <Row className="summary">
        <Col xl={21}>
          <p style={{marginBottom: 0}}>
            Declaro que tengo conocimiento de algún otro conflicto de interés no abordado en las preguntas anteriores y que pudiera afectar o influir de cualquier forma en los intereses de {clientName}, tales como la existencia de relación laboral, comercial, o de negocios con amigos íntimos, parientes por consanguinidad o afinidad hasta tercer y cuarto grado.
          </p>
        </Col>
        <Col span={2} push={1}>
          <Form.Item>
            {form.getFieldDecorator("hasOtherConflictsv2", {
              rules: [
                {required: true, message: t("messages.aml.dontForgetSelect")},
                { 
                  validator: (rule, value, callback) => {
                    if (value === true && records.length === 0) {
                      callback(true);
                    } else {
                      callback(); // Pasar la validación
                    }
                  }
                }
              ],
              initialValue: apiForm?.json?.otrosConflictosSimple?.hasOtherConflictsv2
            })(
              <Radio.Group
                onChange={({ target }) =>
                  handleSaveOnChangeField(form, target.value, sectionName, "hasOtherConflictsv2", "hasOtherConflictsv2")
                }
              >
                <Radio className="radio-switch" value={true}>
                  Sí
                </Radio>
                <Radio className="radio-switch" value={false}>
                  No
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {obj.json.otrosConflictosSimple?.hasOtherConflictsv2 && 
      <Form.Item label={"Descripción de la situación"}>
        {form.getFieldDecorator("conflictDescription", {
          initialValue: apiForm?.json?.sectionName?.conflictDescription,
          rules: [{ required: true, message: t("messages.aml.requiredData") }],
        })(
          <Input.TextArea
            onChange={(e) => handleSaveOnChangeField(form, e.target.value,sectionName, "conflictDescription")}
            style={{width: '100%'}}
          />
        )
        }
        </Form.Item>
      }
    </div>
  );
}
